import React, { FC, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { createLinkHref } from '@moonpig/web-core-link'
import { useExperiment } from '@moonpig/web-core-experiments'
import { ModuleMissionButtonsFragment } from './__generated__/fragment'
import { ModuleSection } from '../../components/ModuleContainer'
import { useTracking } from './tracking'
import { Button } from '../../components/MissionButton/Button'
import { Module } from '../types'
import { Carousel } from '../../components/Carousel'
import { ImageButton } from '../../components/MissionButton/ImageButton'

export const MissionButtonsModule: FC<Module<ModuleMissionButtonsFragment>> = ({
  module: { missionButtons, meta, ...rest },
  context,
}) => {
  const isHomepage = context.layout === 'home'

  const showImageButtons =
    useExperiment('explore-mission-buttons-imagery') === 'enabled' && isHomepage

  const [ref, inView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })

  const { viewList, selectItem } = useTracking(missionButtons, meta)

  useEffect(() => {
    if (inView) {
      trackGAEvent(viewList())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  return (
    <ModuleSection
      {...rest}
      {...context}
      data-testid="module-mission-buttons"
      withContainer
      fullWidthOnMobile
      ref={ref}
      padding={{ py: { xs: 6, lg: 7 } }}
    >
      <Carousel hideScrollButtons={missionButtons.length < 8}>
        {missionButtons
          .map((item, index) => {
            if (showImageButtons) {
              return item.image ? (
                <ImageButton
                  key={item.title}
                  title={item.title}
                  linkHref={createLinkHref({
                    url: item.url,
                    trackEvent: selectItem(index),
                  })}
                  imageUrl={item.image.url}
                />
              ) : null
            }

            return (
              <Button
                key={item.title}
                title={item.title}
                linkHref={createLinkHref({
                  url: item.url,
                  trackEvent: selectItem(index),
                })}
              />
            )
          })
          .filter(Boolean)}
      </Carousel>
    </ModuleSection>
  )
}
