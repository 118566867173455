import { GraphQLContext, gql } from '@moonpig/web-core-graphql'
import { AuthenticatedFetch } from '@moonpig/web-core-fetch'
import { Metrics } from '@moonpig/web-core-monitoring'
import { AllFlagIDs } from '@moonpig/web-explore-flags'
import { ModuleBreadcrumbsFragment } from '../../modules/Breadcrumbs'
import { ModuleHeadingFragment } from '../../modules/Heading'
import { ModuleInfoFragment } from '../../modules/Info'
import { ModuleTilesFragment } from '../../modules/Tiles'
import {
  AddMembershipToBasketActionFragment,
  LinkActionFragment,
} from '../../modules/CtaAction/fragment'
import { ModuleEmailCaptureFragment } from '../../modules/EmailCapture'
import {
  ModuleHeroFragment,
  ModuleDynamicHeroFragment,
} from '../../modules/Hero'
import { ModuleMissionButtonsFragment } from '../../modules/MissionButtons'
import { ModulePlacardsFragment } from '../../modules/Placards'
import { ModuleSEOFragment } from '../../modules/SEO'
import { ModuleUspCarouselFragment } from '../../modules/USPCarousel'
import { PageQuery, PageQueryVariables } from './__generated__/query'
import {
  DynamicHeroResponse,
  getDynamicHeroPromotionVariants,
} from '../../services/dataInference'
import { ModuleCardFragment } from '../../modules/Card'
import { ModuleTabbedProductListsFragment } from '../../modules/TabbedProductLists'
import { ModuleRecommendationsForCustomerFragment } from '../../modules/RecommendationsForCustomer'

type PageQuery_page = PageQuery['page']
type PageQuery_page_Page = Extract<PageQuery_page, { __typename: 'Page' }>
type PageQuery_page_Redirect = Extract<
  PageQuery_page,
  { __typename: 'Redirect' }
>

export const PageQueryGQL = gql`
  ${ModuleHeadingFragment}
  ${ModuleBreadcrumbsFragment}
  ${ModuleInfoFragment}
  ${ModuleTilesFragment}
  ${LinkActionFragment}
  ${AddMembershipToBasketActionFragment}
  ${ModuleSEOFragment}
  ${ModuleEmailCaptureFragment}
  ${ModuleHeroFragment}
  ${ModuleDynamicHeroFragment}
  ${ModulePlacardsFragment}
  ${ModuleUspCarouselFragment}
  ${ModuleMissionButtonsFragment}
  ${ModuleCardFragment}
  ${ModuleTabbedProductListsFragment}
  ${ModuleRecommendationsForCustomerFragment}

  fragment ModuleDeliveryStrip on ModuleDeliveryStrip {
    text
    deliveryStripCta: cta {
      title
      url
      shouldFormat
    }
  }

  fragment ModuleOfferStrip on ModuleOfferStrip {
    text
  }
  fragment ModuleCTAStrip on ModuleCTAStrip {
    text
    backgroundImage {
      url(width: 2293, quality: 80, format: jpg)
      description
      dimensions {
        width
        height
      }
    }
    cta {
      title
      url
      shouldFormat
    }
    banner
    meta {
      variation {
        key
        value
      }
    }
  }

  fragment ModuleRichTextImage on ModuleRichTextImage {
    image {
      url
      description
      dimensions {
        width
        height
      }
    }
    altText
  }

  fragment ModuleVideo on ModuleVideo {
    id
    title
    titleHidden
    subtitle
  }

  fragment ModuleTwoColumnPlacard on ModuleTwoColumnPlacard {
    title
    body
    image {
      urlPrimary: url(width: 882, quality: 80, format: webp)
      urlDefault: url(width: 882, quality: 80, format: jpg)
      description
      dimensions {
        width
        height
      }
    }
    columnWidthVariant: variant
    imagePlacement
    twoColumnPlacardCta: cta {
      title
      url
    }
  }

  query PageQuery(
    $path: String!
    $preview: Boolean
    $variations: [ContentVariationInput!]
  ) {
    page(path: $path, preview: $preview, variations: $variations) {
      __typename
      ... on Redirect {
        url
      }
      ... on Page {
        title
        description
        canonicalSubPath
        noIndex
        openGraph {
          url
          title
          description
          image {
            url
          }
          locale
          siteName
          type
        }
        structuredData {
          json
        }
        layout
        modules {
          __typename
          ...ModuleBreadcrumbs
          ...ModuleHeading
          ... on ModuleDescription {
            description
          }
          ...ModuleHero
          ...ModuleDynamicHero
          ...ModuleMissionButtons
          ...ModuleOfferStrip
          ...ModuleInfo
          ...ModuleRichTextImage
          ...ModuleDeliveryStrip
          ...ModuleCTAStrip
          ...ModuleTiles
          ...ModulePlacards
          ...ModuleSEO
          ...ModuleTwoColumnPlacard
          ...ModuleEmailCapture
          ...ModuleUspCarousel
          ...ModuleCard
          ...ModuleTabbedProductLists
          ...ModuleRecommendationsForCustomer
          ... on ModuleSearchResults {
            url
            title
            subtitle
            departments
            keywords
            facetDetails {
              group
              key
            }
            promotionId
            sponsoredProducts {
              internallyPromoted
              productKey
            }
          }
          ... on ModulePromoTile {
            name
            title
            description
            internallyPromoted
            image {
              url
              description
              dimensions {
                width
                height
              }
            }
            cta {
              title
              action {
                __typename
                ... on LinkAction {
                  ...LinkAction
                }
                ... on AddMembershipToBasketAction {
                  ...AddMembershipToBasketAction
                }
              }
            }
          }
          ...ModuleVideo
          ... on ModuleRichText {
            blocks {
              __typename
              ... on RichTextBlockHTML {
                html
              }
              ... on RichTextBlockModule {
                module {
                  __typename
                  ...ModuleInfo
                  ...ModuleDeliveryStrip
                  ...ModuleCTAStrip
                  ...ModuleTiles
                  ...ModulePlacards
                  ...ModuleVideo
                  ...ModuleRichTextImage
                }
              }
            }
          }
          ... on ModuleBlogPostDate {
            date
          }

          ... on ModulePlaceholder {
            placeholderVariant: variant
          }
          ... on ModuleBlogPostAuthor {
            name
            jobTitle
            image {
              url(width: 105, quality: 80, format: jpg, fit: thumb)
              description
              dimensions {
                width
                height
              }
            }
          }
          ... on ModuleBlogPostCategory {
            category
            url
          }
          ... on ModuleBlogPostsListing {
            title
            items {
              title
              subtitle
              image {
                url(
                  width: 350
                  height: 350
                  quality: 80
                  format: jpg
                  fit: thumb
                )
                description
                dimensions {
                  width
                  height
                }
              }
              url
            }
          }
          ... on ModuleBlogHomepageImage {
            image {
              url(width: 650, height: 80, quality: 80)
              description
              dimensions {
                width
                height
              }
            }
          }
          ... on ModuleBlogPostsCategories {
            items {
              category
              url
            }
          }
        }
      }
    }
  }
`

export const pageQuery = async (
  graphQL: {
    query: GraphQLContext['query']
  },
  input: { path: string; preview: boolean },
): Promise<{
  page: PageQuery_page_Page | null
  redirect: PageQuery_page_Redirect | null
}> => {
  const { data } = await graphQL.query<PageQuery, PageQueryVariables>({
    query: PageQueryGQL,
    variables: {
      path: input.path,
      preview: input.preview,
      variations: [],
    },
  })

  return data.page?.__typename === 'Redirect'
    ? { page: null, redirect: data.page }
    : { page: data.page, redirect: null }
}

type DataInferenceQueryInput = {
  flags: Record<AllFlagIDs, boolean>
  path: string
  isMobile: boolean
  metrics: Metrics
  authEnabled: boolean
}

export const dataInferenceQuery = (
  fetch: AuthenticatedFetch,
  { flags, path, isMobile, metrics, authEnabled }: DataInferenceQueryInput,
): Promise<DynamicHeroResponse> => {
  const pathParts = path.split('/').filter(Boolean)
  const isServerSideDynamicHeroEnabled =
    authEnabled &&
    flags['explore-enable-dynamic-hero'] &&
    pathParts.length === 1 &&
    pathParts[0] === 'uk'

  return isServerSideDynamicHeroEnabled
    ? getDynamicHeroPromotionVariants(fetch, { isMobile, metrics })
    : Promise.resolve({ promotions: [] })
}
