import { gql } from '@moonpig/web-core-graphql'

export const ModuleMissionButtonsFragment = gql`
  fragment ModuleMissionButtons on ModuleMissionButtons {
    missionButtons {
      title
      url
      image {
        description
        dimensions {
          width
          height
        }
        url
      }
    }
    meta {
      experiment {
        key
        value
      }
    }
  }
`
