import React, { FC, useState } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import {
  styled,
  breakpointUp,
  breakpointDown,
  screenReaderOnly,
} from '@moonpig/launchpad-utils'
import {
  Alert,
  Box,
  Flex,
  Heading,
  PrimaryButton,
  Text,
} from '@moonpig/launchpad-components'
import { Form } from '@moonpig/web-shared-components'
import { TextInput } from '@moonpig/launchpad-forms'
import { ClickEventData, TrackedAnchor } from '@moonpig/web-core-analytics'
import { useStoreId } from '@moonpig/web-core-stores'
import { BUTTON_MIN_WIDTH, ILLUSTRATION_ECARD_URL } from './constants'
import { EmailCaptureContainer, HeadingImage } from './Shared'
import { useFindLocaleText } from '../../../text-localisation'

const FORM_MAX_WIDTH = 550

const StyledTextInput = styled(TextInput)<{ withError: boolean }>`
  flex-grow: 1;

  ${breakpointUp('lg')} {
    ${({ withError }) =>
      s({
        mb: withError ? 4 : 13,
      })}
  }
  ${breakpointDown('lg')} {
    ${s({
      mb: 5,
    })}
  }

  label {
    ${screenReaderOnly}
  }
  input + div {
    ${screenReaderOnly}
    ${s({
      color: 'colorTextHeadline',
    })}
  }
`

const StyledFlex = styled(Flex)`
  flex-wrap: nowrap;
  flex-direction: column;

  ${breakpointUp('lg')} {
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  min-width: ${BUTTON_MIN_WIDTH}px;
  order: 1;

  ${s({
    ml: {
      xs: 0,
      lg: 4,
    },
    mb: {
      xs: 10,
      lg: 0,
    },
  })}

  ${breakpointUp('lg')} {
    order: 0;
  }
`

const StyledAlert = styled(Alert)`
  flex-grow: 1;
  ${s({
    mb: 5,
  })}

  ${breakpointUp('lg')} {
    ${s({
      mb: 8,
    })}
  }
`

const StyledTrackedAnchor = styled(TrackedAnchor)`
  ${s({
    display: {
      xs: 'block',
      lg: 'inline',
    },
    color: 'colorTextHeadline',
  })}
  font-weight: 300;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`

type Props = {
  heading: string
  text: string
  onSubmit: (email: string) => void
  error?: string
  privacyNoticeEventData: ClickEventData
}

export const EmailForm: FC<Props> = ({
  heading,
  text,
  onSubmit,
  error,
  privacyNoticeEventData,
}) => {
  const t = useFindLocaleText()
  const storeId = useStoreId()
  const [email, setEmail] = useState('')

  const emailAddressLocalisation = t('find.email_capture.email_address')

  return (
    <EmailCaptureContainer>
      <Box maxWidth={{ md: FORM_MAX_WIDTH }}>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading
            level="h2"
            typography={{
              xs: 'typeMobileDisplay03',
              md: 'typeDesktopDisplay04',
            }}
          >
            {heading}
          </Heading>
          <HeadingImage
            src={ILLUSTRATION_ECARD_URL}
            alt="Illustration Ecard"
            lazyLoad={false}
            aria-hidden
            height={'75px'}
          />
        </Flex>
        <Text as="p" mb={5}>
          {text}
        </Text>
        <Form
          onSubmit={e => {
            e.preventDefault()
            onSubmit(email)
          }}
          method="post"
        >
          <StyledFlex>
            <StyledTextInput
              label={emailAddressLocalisation}
              helperText={emailAddressLocalisation}
              name="email"
              type="email"
              autoCorrect="off"
              spellCheck="false"
              autoComplete="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder={emailAddressLocalisation}
              required
              withError={!!error}
            />
            <StyledPrimaryButton type="submit">
              {t('find.email_capture.sign_up')}
            </StyledPrimaryButton>
            {error && <StyledAlert variant="error">{error}</StyledAlert>}
          </StyledFlex>
        </Form>
        <Text as="p" mb={0}>
          {t('find.email_capture.unsubscribe')} &nbsp;
          <StyledTrackedAnchor
            target="_blank"
            to={`/${storeId}/privacy-notice`}
            eventData={privacyNoticeEventData}
          >
            {t('find.email_capture.privacy_notice')}
          </StyledTrackedAnchor>
        </Text>
      </Box>
    </EmailCaptureContainer>
  )
}
