/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react'
import { Box, RichTextContainer, Image } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { ThemeInterface } from '@moonpig/launchpad-theme'
import { CTAStripModule } from '../CTAStrip'
import { DeliveryStripModule } from '../DeliveryStrip'
import { VideoModule } from '../Video'
import { ContentModule, ModuleRichText } from '../../types'
import { mapModule } from '../map-modules'
import { Module, ModuleContext } from '../types'

const StyledContainer = styled(Box)<{ theme: ThemeInterface }>`
  ${({ theme: { richTextWidth } }) =>
    s({ maxWidth: richTextWidth, mx: 'auto' })}
`

const py = { xs: 8, lg: 10 }

const renderModule = ({
  module,
  context,
}: {
  module: ContentModule
  context: ModuleContext
}) => {
  switch (module?.type) {
    case 'ModuleDeliveryStrip':
      return <DeliveryStripModule key={context.index} {...module} />
    case 'ModuleCTAStrip':
      return (
        <CTAStripModule
          key={context.index}
          trackingData={{
            moduleIndex: context.index,
            moduleType: module.type,
          }}
          {...module}
        />
      )
    case 'ModuleVideo':
      return (
        <Box py={py} key={context.index}>
          <StyledContainer>
            <VideoModule {...module} />
          </StyledContainer>
        </Box>
      )
    case 'ModuleRichTextImage':
      return (
        <Box py={10} key={context.index}>
          <RichTextContainer>
            <Image src={module.image.url} width="100%" alt={module.altText} />
          </RichTextContainer>
        </Box>
      )
    case 'ModuleInfo':
    case 'ModuleTiles':
    case 'ModulePlacards':
      return mapModule(module, context)
    default:
      return null
  }
}

export const RichTextModule: FC<Module<ModuleRichText>> = ({
  module,
  context,
}) => (
  <>
    {/* eslint-disable-next-line array-callback-return */}
    {module.blocks.map((block, index) => {
      switch (block.type) {
        case 'RichTextBlockHTML':
          return (
            <Box key={index} py={py}>
              <RichTextContainer>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: block.html }} />
              </RichTextContainer>
            </Box>
          )
        case 'RichTextBlockModule':
          return renderModule({
            module: block.module,
            context: { ...context, index },
          })
      }
    })}
  </>
)
