import React, { FC } from 'react'
import { ModulePlaceholderVariant } from '@moonpig/web-explore-types-graphql'
import { ContinueCreationModuleModule } from './variant/ContinueCreationModule'
import { ShopForReminderModule } from './variant/ShopForReminder/ShopForReminderModule'
import { Bundles } from '../Bundles'
import { ModulePlaceholder } from '../../types'
import { HomepageRecentlyViewedModule } from '../HomepageRecentlyViewed'
import { ModuleSection } from '../../components/ModuleContainer'
import { ContinueJourney } from '../ContinueJourney/ContinueJourney'
import { CustomerSatisfaction } from '../../components/CustomerSatisfaction'

export const PlaceholderModule: FC<ModulePlaceholder> = props => {
  const { placeholderVariant, trackingDataProps, backgroundColor } = props

  switch (placeholderVariant) {
    case ModulePlaceholderVariant.Drafts:
      return (
        <ModuleSection data-testid={`module-placeholder-${placeholderVariant}`}>
          <ContinueCreationModuleModule
            {...props}
            variant={placeholderVariant}
          />
        </ModuleSection>
      )
    case ModulePlaceholderVariant.Reminders:
      return (
        <ModuleSection data-testid={`module-placeholder-${placeholderVariant}`}>
          <ShopForReminderModule />
        </ModuleSection>
      )
    case ModulePlaceholderVariant.Bundles:
      return (
        <ModuleSection data-testid={`module-placeholder-${placeholderVariant}`}>
          <Bundles
            trackingDataProps={{
              pageLocation: trackingDataProps?.pageLocation as string,
              moduleIndex: trackingDataProps?.moduleIndex as number,
              modulesLength: trackingDataProps?.modulesLength as number,
            }}
          />
        </ModuleSection>
      )
    case ModulePlaceholderVariant.RecentlyViewed:
      return (
        <HomepageRecentlyViewedModule
          type="ModuleRecentlyViewed"
          trackingDataProps={{
            pageLocation: trackingDataProps?.pageLocation as string,
          }}
          backgroundColor={backgroundColor}
        />
      )
    case ModulePlaceholderVariant.ContinueJourney:
      return (
        <ModuleSection
          padding={{ py: { xs: 6, lg: 8 } }}
          data-testid={`module-placeholder-${placeholderVariant}`}
          withContainer
          fullWidthOnMobile
        >
          <ContinueJourney
            tracking={{
              pageLocation: trackingDataProps?.pageLocation as string,
              moduleProductListIndex: 1,
              moduleProductListsLength: 1,
            }}
          />
        </ModuleSection>
      )
    case ModulePlaceholderVariant.CustomerSatisfaction:
      return (
        <ModuleSection data-testid={`module-placeholder-${placeholderVariant}`}>
          <CustomerSatisfaction />
        </ModuleSection>
      )
    default:
      return null
  }
}
